<div class="uk-container uk-container-small">
  <div class="uk-margin uk-padding-small">
    <ul class="uk-list uk-list-large uk-list-divider">

      <!-- BEGIN search result entry -->
      <li id="atxAiOfZWmd2" *ngFor="let searchResult of searchResults" class="searchResult uk-padding-xsmall">
        <div class="uk-text-center uk-grid-column-collapse" uk-grid>
          <div class="uk-width-1-3@m">
            <div class="uk-padding-small uk-padding-remove-top uk-padding-remove-bottom">
              <h1 id="at4dW62g5g"
                  class="uk-text-uppercase uk-text-small uk-text-bold uk-text-left uk-padding-remove-bottom uk-margin-remove">
                {{ searchResult.openfda.brand_name | shortestValueFromArray | truncate:[18, '...'] }}
                <span uk-icon="icon: info; ratio: 1"></span>
                <div class="uk-padding-small uk-width-medium" uk-dropdown="pos: bottom-left">
                  <div>
                    <p class="uk-text-small uk-margin-remove uk-text-normal">Brand names:</p>
                    <ul class="uk-list uk-list-square uk-list-collapse uk-margin-remove">
                      <li id="atu6cj420r" *ngFor="let brand_name of searchResult.openfda.brand_name"
                          class="uk-text-muted uk-text-light">{{ brand_name }}</li>
                    </ul>
                    <hr>
                    <p class="uk-text-small uk-margin-remove uk-text-normal">Generic names:</p>
                    <ul class="uk-list uk-list-square uk-list-collapse uk-margin-remove">
                      <li id="atKnCoWLkO" *ngFor="let brand_name of searchResult.openfda.generic_name"
                          class="uk-text-muted uk-text-light">{{ brand_name }}</li>
                    </ul>
                  </div>
                </div>
              </h1>
              <div id="atq5fs4sCz" class="uk-text-small uk-text-muted uk-text-left uk-margin-remove">
                {{ searchResult.openfda.manufacturer_name | shortestValueFromArray | truncate:[20, '...'] }}
                <span uk-icon="icon: info; ratio: 1"></span>
                <div class="uk-padding-small" uk-dropdown="pos: bottom-left">
                  <div>
                    <p class="uk-text-small uk-margin-remove uk-text-normal">Manufacturer names:</p>
                    <ul class="uk-list uk-list-square uk-list-collapse uk-margin-remove">
                      <li id="atsTwlR8op" *ngFor="let manufacturer_name of searchResult.openfda.manufacturer_name"
                          class="uk-text-muted uk-text-light">{{ manufacturer_name }}</li>
                    </ul>
                  </div>
                </div>
                <br/>
                <div id="atKk5bWLhP"
                     class="uk-text-small uk-text-light uk-text-muted uk-text-left uk-padding-remove-bottom ">
                  {{ searchResult.application_number }}
                </div>
              </div>

              <!-- labels and patents counts tags have been deprecated -->
            </div>
          </div>
          <div
            class="uk-width-expand@m uk-width-expand@m uk-padding-xsmall uk-padding-remove-top uk-dotted-border-left uk-dotted-border-right">
            <div class="uk-text-left uk-text-small uk-text-light">Active Ingredients</div>
            <div class="uk-padding-remove">
              <div>
                <ul class="uk-list uk-margin-remove-bottom">
                  <li id="at1quWkAQR" *ngFor="let substance_name of searchResult.openfda.substance_name"
                      class="uk-text-lowercase uk-text-small uk-text-muted uk-text-left uk-margin-remove-top">{{ substance_name }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="uk-width-1-4@m">
            <div class="uk-padding-remove">
              <div class="uk-margin-xsmall-bottom">
              </div>
              <div>
                <a id="atHh0sP8uJ" class="uk-button uk-button-default uk-margin-small-top"
                   (click)="goToDrug(searchResult.application_number)">View Labels</a>
              </div>
            </div>
          </div>
        </div>
      </li>
      <!-- END search result entry -->

    </ul>
  </div>
</div>
