<!-- template file for the Navbar component, navbar.component.ts -->
<div uk-sticky="media: 960"
     class="uk-navbar-container tm-navbar-container uk-sticky uk-sticky-fixed uk-active uk-sticky-below gradientBlueBackground"
     style="position: fixed; top: 0px; width: 1500px;">
  <div class="uk-container uk-container-large">
    <nav class="uk-navbar">
      <div class="uk-navbar-left"><a id="atUkrIiXCJ" href="../" class="uk-navbar-item uk-logo fw200 whiteText">
        pharma
        <span class="fw500">DB</span>
      </a></div>
      <div class="uk-navbar-right">
        <ul class="uk-navbar-nav uk-visible@m">
          <li><a id="atxGLZJsst" class="navbarLink whiteText" routerLink="/search">Search</a></li>
          <li><a id="atCjFjyboi" class="navbarLink whiteText" href="https://github.com/pharmaDB" target="_blank">GitHub</a></li>
          <li><a id="atwRagJvgB" class="navbarLink whiteText" [href]="bulkDownloadURL">Bulk Download</a></li>
        </ul>
      </div>
    </nav>
  </div>
</div>
