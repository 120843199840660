<div class="uk-margin-top" uk-grid>

  <div class="uk-width-1-3@m">
    <div class="uk-text-left">
      <!-- present the Drug name of the shortest Drug name available for the drug, truncating it if necessary -->
      <h1 id="atxMt5NQIk"
        class="uk-heading-small drug-header uk-margin-remove-bottom">{{ drug.metadata.brandNames | shortestValueFromArray | truncate:[18, '...'] }}
          <span id="atSJm7uqUZ" class="uk-label uk-background-muted uk-text-muted ndaNumberLabel">{{ drug.applicationNumber }}</span>
        <!-- clickable icons for opening modals to view additional drug metadata and drug images (if images are available) -->
        <a id="at6jgPnRZd" href="#drug-info-modal" uk-toggle class="uk-margin-small-left uk-margin-small-right"><span uk-icon="info"></span></a>
        <a id="atAl3S6CYL" *ngIf="drug.drugImages.length > 0" href="#drug-images-modal" uk-toggle class="uk-margin-small-right"><span uk-icon="image"></span></a>
      </h1>
    </div>
  </div>
  <div class="uk-width-expand@m">
    <div class="uk-text-right">
      <!-- enabled this button to re-enable the selection of a specific set id -->
      <button class="uk-button uk-button-default proximaNovaRegular dark-text" type="button" disabled>SET ID: {{ drugViewConfig.selectedDrugLabelSetID }}</button>
      <div uk-dropdown>
        <ul class="uk-nav uk-dropdown-nav">
          <li *ngFor="let setId of drugViewConfig.drugLabelSetIDs">
            <a (click)="handleSetIdClicked(setId)" class="uk-text-left">SET ID: {{ setId }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<hr class="uk-margin-remove-top"/>

<!-- BEING drug metadata modal -->
<div id="drug-info-modal" uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <h2 class="uk-modal-title">Additional Drug Information</h2>
    <div class="uk-grid-small" uk-grid>
      <div class="uk-width-expand" uk-leader>Application Number</div>
      <div id="atLVftJWmL">{{ drug.applicationNumber }}</div>
    </div>
    <div class="uk-grid-small" uk-grid>
      <div class="uk-width-expand" uk-leader>Brand Names</div>
      <div id="at8mZ2kNtz">{{ drug.metadata.brandNames }}</div>
    </div>
    <div class="uk-grid-small" uk-grid>
      <div class="uk-width-expand" uk-leader>Generic Names</div>
      <div id="at27DwJpgX">{{ drug.metadata.genericName }}</div>
    </div>
    <div class="uk-grid-small" uk-grid>
      <div class="uk-width-expand" uk-leader>Substance Names</div>
      <div id="at2jOHMyre">{{ drug.metadata.substanceName }}</div>
    </div>
    <div class="uk-grid-small" uk-grid>
      <div class="uk-width-expand" uk-leader>SPL ID</div>
      <div id="atSUyZTk1U">{{ drug.metadata.splId }}</div>
    </div>
    <div class="uk-grid-small" uk-grid>
      <div class="uk-width-expand" uk-leader>Manufacturer Name</div>
      <div id="atSFUh0fWM">{{ drug.metadata.manufacturerName }}</div>
    </div>
    <p class="uk-text-right">
      <button id="atFayrQuql" class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
    </p>
  </div>
</div>
<!-- END drug metadata modal -->

<!-- BEGIN drug images modal -->
<div *ngIf="drug.drugImages.length > 0" id="drug-images-modal" class="uk-flex-top" uk-modal>
  <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">

    <button class="uk-modal-close-default" type="button" uk-close></button>

    <!-- show drug images in a slide show with next and previous buttons -->
    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow="ratio: 1:1; finite: true">

      <ul class="uk-slideshow-items">
        <li *ngFor="let image of drug.drugImages, let i = index">
          <img id="atoUbsA9Vh" src="{{ image }}" alt="">
          <p id="atGMJmBY24" class="drug-image-text">{{ i + 1 }} / {{ drug.drugImages.length }}</p>
        </li>
      </ul>

      <a id="atyNvcJFgo" class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous
         uk-slideshow-item="previous"></a>
      <a id="atBN5MnZZK" class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next
         uk-slideshow-item="next"></a>

    </div>
  </div>
</div>
<!-- END drug images modal -->
