<div class="uk-grid-divider uk-child-width-expand@s text-container" uk-grid>

  <!-- welcome/what to do well -->
  <div *ngIf="(drugViewConfig.drugViewMode === DrugViewMode.none)">
    <article class="uk-article">
      <div id="atuKha4jxg" class="uk-placeholder uk-text-center uk-margin-large">Select a patent or a label from the
        timeline to begin
      </div>
    </article>
  </div>

  <!-- Drug label -->
  <div class="text-container-left hide-scroll-bar"
       *ngIf="((drugViewConfig.drugViewMode === DrugViewMode.labelDiff || DrugViewMode.historicalLabelDiff) && (drugViewConfig.inViewLabelOne))">
    <span>
      <article class="uk-article label-text-container">
        <h1 id="attRMvsVY1" class="uk-article-title proximaNovaThin uk-margin-remove-bottom">
          <span [ngSwitch]="drugViewConfig.drugViewMode">
            <span *ngSwitchCase="DrugViewMode.labelDiff">
              Single Label Diff
            </span>
            <span *ngSwitchCase="DrugViewMode.historicalLabelDiff">
              Historical Label Diff
            </span>
          </span>
          <button (click)="handleToggleDrugView()" href="#" class="uk-button uk-button-default float-right"
                  type="button">
            <span [ngSwitch]="drugViewConfig.drugViewMode">
              <span *ngSwitchCase="DrugViewMode.labelDiff">
                Switch to Historical Diff
              </span>
              <span *ngSwitchCase="DrugViewMode.historicalLabelDiff">
                Switch to Label Diff
              </span>
            </span>
          </button>
        </h1>

        <!-- Historical Drug Label Diff View -->
        <span *ngIf="((drugViewConfig.drugViewMode === DrugViewMode.historicalLabelDiff) && drugViewConfig.labelDiff)">
          <p class="uk-article-meta proximaNovaRegular uk-margin-remove-top">
            <span class="uk-label uk-label-success"
                  style="background:{{ drugViewConfig.inViewLabelOne.color }}; color:{{ drugViewConfig.inViewLabelOne.colorDarkened }}">
              <strong>Historical additions up to:</strong> Label version {{ drugViewConfig.inViewLabelOne.drugLabel.spl_version }}
              , published on {{ drugViewConfig.inViewLabelOne.drugLabel.published_date }}
            </span>
            <span class="float-right">
              <!-- DailyMed link to the full drug label -->
              <a id="atAXOY2ZJE" target="_blank"
                 href="{{'https://dailymed.nlm.nih.gov/dailymed/lookup.cfm?setid=' + drugViewConfig.inViewLabelOne.drugLabel.set_id + '&version=' + drugViewConfig.inViewLabelOne.drugLabel.spl_version}}">
                View entire label on DailyMed
                <span uk-icon="icon: arrow-right;"></span>
              </a>
            </span>
          </p>

          <ul uk-accordion="active: 0; multiple: true">
            <li *ngFor="let section of drugViewConfig.labelDiff"
                class="uk-padding-small uk-margin-small-bottom">
                <a id="atoiOjOhqd" class="uk-accordion-title uk-text-lead proximaNovaBold uk-margin-remove-bottom"
                   href="#">
                  {{ section.name }}
                </a>
                <div class="uk-accordion-content">
                  <span id="atf1qXuXEj" *ngFor="let textElement of section.endText">
                    <span *ngIf="textElement.assocaitedAddition"
                          style="background:{{ textElement.assocaitedAddition.timelineItem.color }}; color:{{ textElement.assocaitedAddition.timelineItem.colorDarkened }}"
                          (click)="diffAdditionClickHandler(textElement.assocaitedAddition.diffElement)">
                      {{ textElement.text }}
                    </span>
                    <span *ngIf="!textElement.assocaitedAddition">
                      {{ textElement.text }}
                    </span>
                  </span>
                </div>
            </li>
          </ul>
        </span>
        <!-- END Historical drug label diff view -->

        <!-- Single Drug Label Diff View -->
        <span *ngIf="((drugViewConfig.drugViewMode === DrugViewMode.labelDiff) && (drugViewConfig.inViewLabelOne))">
        <p class="uk-article-meta proximaNovaRegular uk-margin-remove-top">
          <span class="uk-label uk-background-muted uk-text-secondary">
            <strong>Base:</strong> Label version {{ drugViewConfig.inViewLabelOne.drugLabel.previous_label_spl_version }}
            , published on {{ drugViewConfig.inViewLabelOne.drugLabel.previous_label_published_date }}
          </span>
          <span class="uk-margin-small-left uk-margin-small-right" uk-icon="icon: arrow-right;"></span>
          <span class="uk-label uk-label-success"
                style="background:{{ drugViewConfig.inViewLabelOne.color }}; color:{{ drugViewConfig.inViewLabelOne.colorDarkened }}">
            <strong>Compared:</strong> Label version {{ drugViewConfig.inViewLabelOne.drugLabel.spl_version }}, published on {{ drugViewConfig.inViewLabelOne.drugLabel.published_date }}
          </span>
          <span class="float-right">
            <!-- DailyMed link to the full drug label -->
            <a id="atAXOY2ZJE" target="_blank"
               href="{{'https://dailymed.nlm.nih.gov/dailymed/lookup.cfm?setid=' + drugViewConfig.inViewLabelOne.drugLabel.set_id + '&version=' + drugViewConfig.inViewLabelOne.drugLabel.spl_version}}">
              View entire label on DailyMed
              <span uk-icon="icon: arrow-right;"></span>
            </a>
          </span>
        </p>

        <ul uk-accordion="active: 0; multiple: true">
          <li *ngFor="let section of drugViewConfig.inViewLabelOne.drugLabel.diff_against_previous_label"
              class="uk-padding-small uk-margin-small-bottom">
              <a id="atoiOjOhqd" class="uk-accordion-title uk-text-lead proximaNovaBold uk-margin-remove-bottom"
                 href="#">
                {{ section.name }}
                <span *ngIf="(section | getDiffAdditionsCount) > 0">
                  <span
                    class="uk-label uk-label-success uk-margin-small-left proximaNovaRegular">Additions: {{ section | getDiffAdditionsCount }}</span>
                </span>
                <span *ngIf="(section | getDiffDeletionsCount) > 0">
                  <span
                    class="uk-label uk-label-danger uk-margin-small-left proximaNovaRegular">Deletions: {{ section | getDiffDeletionsCount }}</span>
                </span>
              </a>
              <div class="uk-accordion-content">
                  <p>
                    <!-- and or every diff element therein -->
                    <span id="atf1qXuXEj" *ngFor="let textDiffElement of section.text">

                      <!-- if the diff is an addition (1) give it green highlighting via CSS class-->
                      <!-- if the diff is an deletion (-1) give it red highlighting via CSS class -->
                      <!-- if the diff is an equality (0) do nothing in terms of CSS class -->
                      <span [ngSwitch]="textDiffElement[0]">
                        <span *ngSwitchCase="0" class="" [innerHTML]="textDiffElement[1]">
                        </span>
                        <span *ngSwitchCase="1">
                          <span *ngIf="textDiffElement[3]" (click)="diffAdditionClickHandler(textDiffElement)"
                                class="diff-added-text pointer" [innerHTML]="textDiffElement[1]">
                          </span>
                          <span *ngIf="!textDiffElement[3]" class="diff-added-text" [innerHTML]="textDiffElement[1]">
                          </span>
                        </span>
                        <span *ngSwitchCase="-1" class="diff-deleted-text" [innerHTML]="textDiffElement[1]">
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </li>
        </ul>
          <!-- END single drug label view diff -->

        </span>
      </article>
    </span>
  </div>

  <!-- Drug Patent -->
  <div *ngIf="(drugViewConfig.isPatentInView && drugViewConfig.inViewPatent)"
       class="uk-width-1-3@m text-container-right hide-scroll-bar">
      <span>
        <article class="uk-article patent-text-container">
          <!-- show the patent number of the in-view patent and provide an option to close the patent panel -->
          <h1 id="atRJvN9sIx" class="uk-article-title proximaNovaThin">
            Patents
            <span (click)="closePatentViewClickHandler()" class="float-right uk-link uk-article-meta"
                  uk-icon="icon: close;"></span>
          </h1>
          <div class="uk-alert-primary" uk-alert>
            <a class="uk-alert-close" uk-close></a>
              <p>Cosign similarity is used to score how well a portion of label text relates to a patent claim. We consider scores of <strong>.616 or more</strong> to be significant.</p>
          </div>
          <span class="proximaNovaBold">
            Expanded Context
            <hr class="uk-margin-remove-top uk-margin-small-bottom"/>
          </span>
          <span class="proximaNovaThin">
            "{{ drugViewConfig.inViewPatent.diffElement[3].expanded_content }}"
          </span>

          <div *ngFor="let score of drugViewConfig.inViewPatent.diffElement[3].scores" class="uk-margin-medium-top">
            <span class="proximaNovaBold">
              PATENT {{ score.patent_number }}, CLAIM {{ score.claim_number }}
              , SCORE: {{ score.score | number : '0.0-2'}}
              <span id="ati1QtqPO5" class="float-right uk-article-meta proximaNovaRegular">
                <!-- Google Patents link to the full patent -->
                <a id="atNeyANkYl" target="_blank"
                   href="{{'https://patents.google.com/patent/US' + score.patent_number + '/en'}}">
                  Google Patents
                  <span uk-icon="icon: arrow-right;"></span>
                </a>
              </span>
              <hr class="uk-margin-remove-top uk-margin-small-bottom"/>
            </span>
            <span class="proximaNovaRegular">
              {{ score.claim.claim_text }}

              <ul uk-accordion class="uk-margin-small-top">
                <li *ngFor="let parentClaim of score.parentClaims">
                    <a
                      class="uk-accordion-title parent-claim-subheader proximaNovaBold">Parent | Claim {{ parentClaim.claim_number }}</a>
                    <div class="uk-accordion-content proximaNovaRegular">
                      {{ parentClaim.claim_text }}
                    </div>
                </li>
              </ul>
            </span>
          </div>
        </article>
      </span>
  </div>

